:root {
  --primary-dark: #0b74a5;
  --primary-main: #10a6ed;
  --primary-light: #3fb7f0;
  --primary-light2: #33c0ff;
  --primary-extra-light: #71d3ff;
  --secondary-main: #3c4481;
  --secondary-dark: #2a2f5a;
  --secondary-light: #63699a;
  --error-dark: #91373a;
  --error-light: #d97275;
  --error-main: #d04f53;
  --warning-main: #f19c00;
  --warning-dark: #a86d00;
  --warning-light: #f3af33;
  --info-dark: #0e3f80;
  --info-main: #155bb7;
  --info-light: #437bc5;
  --success-main: #4caf50;
  --success-dark: #357a38;
  --success-light: #6fbf73;
  --misc-01-dark: #85277b;
  --misc-01-main: #be39b1;
  --misc-01-light: #cb60c0;
  --neutral-06: #000;
  --neutral-05: #424242;
  --neutral-04: #5f6367;
  --neutral-03: #a0a0a0;
  --neutral-02: #f3f3f3;
  --neutral-01: #fff;
}

.filterBox {
  display: flex;
  justify-content: space-between;
}
