.usersContainer {
  display: flex;
  width: auto;
}

.usersTable {
  flex-grow: 1;
}

.usersRolesList {
  width: 25%;
  margin-left: 25px;
  flex-grow: 1;
}
